import { TxnWithResolve, useExecutionQueue } from '@/store/executionQueue';
import { privateKeyToAccount } from 'viem/accounts';
import { http, useAccount, usePublicClient } from 'wagmi';
import useAppToast from '../shared/useAppToast';
import { useEffect, useRef } from 'react';
import { captureError } from '@/lib/sentry';
import { serializeError } from '@/lib/transaction';
import ConnectionManager from '@/lib/connectionManager';
import { createWalletClient } from 'viem';
import { DEDICATED_RPC } from '@/lib/env';
import { getTransactionCount } from 'viem/actions';

const gasLimitByTransactionType: Record<string, number> = {
  openTrade: 600_000,
  closeTradeMarket: 500_000,
  cancelOpenLimitOrder: 200_000,
  updateMargin: 900_000,
  updateTpAndSl: 1_000_000,
};

const useProcessTxQueue = () => {
  const { address, chain } = useAccount();
  const { executionQueue, pop } = useExecutionQueue();
  const client = usePublicClient();
  const { failed, txnSuccess } = useAppToast();

  // Ref to prevent multiple simultaneous queue processing
  const isProcessingRef = useRef(false);

  const execute = async (executionQueue: TxnWithResolve[]) => {
    if (isProcessingRef.current || executionQueue.length === 0) {
      return;
    }

    try {
      isProcessingRef.current = true;

      const queueCopy = [...executionQueue];

      while (queueCopy.length > 0) {
        const txToExecute = queueCopy.shift();

        if (!txToExecute) continue;

        try {
          const { tx, privateKey, feedId } = txToExecute;

          const account = privateKeyToAccount(privateKey);

          const oneCTWalletClient = createWalletClient({
            account,
            chain,
            transport: http(DEDICATED_RPC),
          });

          let args = [...tx.args];
          if (feedId) {
            const priceUpdateData = await (
              await ConnectionManager.getConnection()
            ).getPriceFeedsUpdateData([feedId]);

            args[args.length - 1] = priceUpdateData;
          }

          const nonce = await getTransactionCount(client, {
            address: oneCTWalletClient.account.address,
            blockTag: 'pending',
          });

          const simulationResult = await client.simulateContract({
            ...tx,
            nonce,
            args,
            chain,
            account: oneCTWalletClient.account,
            gas: BigInt((gasLimitByTransactionType[tx.functionName] || 100_000) * 1.3),
          });

          const txHash = await oneCTWalletClient.writeContract(simulationResult.request);

          const receipt = await client.waitForTransactionReceipt({
            hash: txHash!,
            timeout: 20000,
          });

          pop();

          if (receipt.status === 'success') {
            txnSuccess(txHash!);
            tx.txSuccessCallback?.();
            continue;
          }

          console.log({ receipt });

          failed({ error: 'Transaction failed.' });
          tx.txFailedCallback?.();
        } catch (e) {
          pop();
          const error = serializeError(e);
          const txToExecuteWithoutPrivateKey = {
            ...txToExecute,
            privateKey: undefined,
            tx: {
              ...txToExecute.tx,
              args: txToExecute.tx.args.join(','),
            },
          };

          captureError(e, {
            address,
            action: 'processTxQueue',
            tx: txToExecuteWithoutPrivateKey,
          });

          console.log(e);

          failed({ error: error.message });
        }
      }
    } catch (e) {
      captureError(e, {
        address,
        action: 'processTxQueue',
      });
    } finally {
      isProcessingRef.current = false;
    }
  };

  useEffect(() => {
    execute(executionQueue);
  }, [executionQueue.length]);
};

export default useProcessTxQueue;
