import { useQuery } from '@tanstack/react-query';
import useLeaguesInfo from './useLeaguesInfo';
import { fetchUserInfo } from '@/services/leagues/api';
import { useAccount } from 'wagmi';
import { useLeagueStore } from '@/store/leagues';
const useLeaguesUserInfo = () => {
  const {
    leaguesInfo
  } = useLeaguesInfo();
  const {
    address
  } = useAccount();
  const {
    selectedWeek
  } = useLeagueStore();
  const {
    data: leaguesUserInfo,
    refetch: refetchLeaguesUserInfo,
    isFetched
  } = useQuery({
    queryKey: ['leagues-user-info', address, leaguesInfo, selectedWeek],
    queryFn: () => fetchUserInfo({
      seasonIdentifier: leaguesInfo?.identifier ?? '',
      epochNumber: selectedWeek !== null ? selectedWeek === 0 ? -1 : selectedWeek : 4,
      userAddress: address ?? '0x'
    }),
    refetchInterval: 120_000,
    enabled: !!address
  });
  return {
    leaguesUserInfo,
    refetchLeaguesUserInfo,
    isFetched
  };
};
export default useLeaguesUserInfo;