import { EvmPriceServiceConnection } from '@pythnetwork/pyth-evm-js';
import { HERMES_URL } from './env';
import { captureError } from './sentry';

type Callback = (data: any) => void;

export default class ConnectionManager {
  private static instance: ConnectionManager | null = null;
  private connection: EvmPriceServiceConnection | null = null;
  private callbacks: { [key: string]: Callback } = {};

  private constructor() {}

  static getInstance(): ConnectionManager {
    if (!ConnectionManager.instance) {
      ConnectionManager.instance = new ConnectionManager();
    }
    return ConnectionManager.instance;
  }

  private async getConnection(): Promise<EvmPriceServiceConnection> {
    return new Promise((resolve) => {
      if (!this.connection) {
        this.connection = new EvmPriceServiceConnection(HERMES_URL);

        this.connection.onWsError = (error) => {
          captureError(`Price feed error: ${error?.message}`, { error });
          setTimeout(() => {
            this.connection = new EvmPriceServiceConnection(HERMES_URL);
            resolve(this.connection);
          }, 500);
        };
      }

      if (this.connection) {
        resolve(this.connection);
      }
    });
  }

  private subscribePriceFeedUpdates(feedIds: string[]): void {
    this.getConnection().then((connection) => {
      connection.subscribePriceFeedUpdates(feedIds, (data: any) => {
        Object.values(this.callbacks).forEach((callback) => callback(data));
      });
    });
  }

  private registerCallback(name: string, callback: Callback): string {
    this.callbacks[name] = callback;
    return name;
  }

  private unregisterCallback(name: string): void {
    delete this.callbacks[name];
  }

  private closeConnection(): void {
    if (this.connection) {
      this.connection.closeWebSocket();
      this.connection = null;
    }
  }

  static getConnection(): Promise<EvmPriceServiceConnection> {
    return ConnectionManager.getInstance().getConnection();
  }

  static subscribePriceFeedUpdates(feedIds: string[]): void {
    ConnectionManager.getInstance().subscribePriceFeedUpdates(feedIds);
  }

  static registerCallback(name: string, callback: Callback): string {
    return ConnectionManager.getInstance().registerCallback(name, callback);
  }

  static unregisterCallback(name: string): void {
    ConnectionManager.getInstance().unregisterCallback(name);
  }

  static closeConnection(): void {
    ConnectionManager.getInstance().closeConnection();
  }
}
