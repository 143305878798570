import { pairNames } from '@/constants/pairs';
import usePairs, { IPair } from '@/hooks/pairs/usePairs';
import { cn, currencyIcons } from '@/lib/utils';
import Image from 'next/image';
export enum PairLabelType {
  PairOnly = 'pair-only',
  PairAndName = 'pair-and-name',
}
export const PairName = ({
  pair,
  type,
  pairColor = 'text-grey-000',
  pairNameColor = 'text-grey-200'
}: {
  pair: {
    from: string;
    to: string;
  };
  type: PairLabelType;
  pairColor?: string;
  pairNameColor?: string;
}) => {
  if (type === PairLabelType.PairOnly) {
    return <div className="flex items-center gap-1">
        <p className={`${pairColor}`}>
          {pair.from} - {pair.to}
        </p>
      </div>;
  } else {
    return <div className="flex flex-col items-start gap-1">
        <p className={`${pairColor} text-base`}>{pairNames[`${pair.from}/${pair.to}`]}</p>
        <p className={`${pairNameColor} text-sm`}>
          {pair.from} / {pair.to}
        </p>
      </div>;
  }
};
export const PairIcon = ({
  pairInfo,
  size
}: {
  pairInfo: IPair;
  size: string;
}) => {
  if (pairInfo.groupIndex === 2) {
    return <Image className={size} src={`/images/pairs/forex/${currencyIcons(`${pairInfo.from}-${pairInfo.to}`)}.svg`} height={36} width={36} alt={`${pairInfo.from}-${pairInfo.to}`} />;
  } else {
    return <Image className={cn('rounded-full', size)} src={`/images/pairs/crypto/${pairInfo.from}.svg`} height={36} width={36} alt={`${pairInfo.from}/${pairInfo.to}`} />;
  }
};
const PairLabel = ({
  selectedPair,
  type,
  size
}: {
  selectedPair: number;
  type: PairLabelType;
  size?: string;
}) => {
  const {
    pairsObj
  } = usePairs();
  const selectedPairInfo = pairsObj[selectedPair];
  return <div className="flex items-center gap-4" data-sentry-component="PairLabel" data-sentry-source-file="PairLabel.tsx">
      <PairIcon pairInfo={selectedPairInfo} size={size ? size : type === PairLabelType.PairOnly ? 'size-7' : 'size-9'} data-sentry-element="PairIcon" data-sentry-source-file="PairLabel.tsx" />
      <div className="hidden md:inline-flex">
        <PairName pair={selectedPairInfo} type={type} data-sentry-element="PairName" data-sentry-source-file="PairLabel.tsx" />
      </div>
      <div className="md:hidden">
        <PairName pair={selectedPairInfo} type={PairLabelType.PairOnly} data-sentry-element="PairName" data-sentry-source-file="PairLabel.tsx" />
      </div>
    </div>;
};
export default PairLabel;