import Image from 'next/image';
import { Input } from '../ui/input';
import { cn, nFormatter } from '@/lib/utils';
import { noOp } from '@/constants/common';
export const DefaultRightSide = () => {
  return <div className="flex min-w-fit gap-2 mr-3 items-center" data-sentry-component="DefaultRightSide" data-sentry-source-file="InputWithBalance.tsx">
      <p className="text-base font-normal text-grey-300-disabled-text">USDC</p>
      <Image src="/images/pairs/usdc.svg" alt="USDC" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="InputWithBalance.tsx" />
    </div>;
};
const InputWithBalance = ({
  amount,
  handleChange,
  balance,
  header,
  handleMaxClick = noOp,
  precision = 2,
  error,
  rightSide
}: {
  amount: string;
  handleChange: React.ChangeEventHandler;
  balance: string | number;
  header: string;
  handleMaxClick: () => void;
  precision?: number;
  error?: boolean;
  rightSide?: React.ReactNode;
}) => {
  return <div className="flex flex-col w-full" data-sentry-component="InputWithBalance" data-sentry-source-file="InputWithBalance.tsx">
      <div className="flex justify-between">
        <p className="text-grey-100 font-normal text-sm">{header}</p>
        <div className="flex items-center gap-2">
          <Image src="/images/icons/wallet.svg" alt="wallet" width={20} height={20} data-sentry-element="Image" data-sentry-source-file="InputWithBalance.tsx" />
          <p className="font-fono text-sm font-normal text-grey-100">
            {nFormatter(+balance, precision)}
          </p>
          <p className="text-sm text-primary-100 cursor-pointer" onClick={() => {
          handleMaxClick();
        }}>
            Max
          </p>
        </div>
      </div>
      <div className={cn('border-grey-400 border bg-grey-600 flex gap-3 justify-between mt-2 rounded-lg h-full', error && 'border-error-100')}>
        <div className="py-2 pl-3 flex-1">
          <Input className="bg-grey-600 w-full border-0 ring-0 focus-visible:ring-0 focus-visible:ring-offset-0 h-6 p-0 text-grey-000 font-medium text-base" value={amount} onChange={handleChange} data-sentry-element="Input" data-sentry-source-file="InputWithBalance.tsx" />
        </div>
        {rightSide ? rightSide : <DefaultRightSide />}
      </div>
    </div>;
};
export default InputWithBalance;