import { CLARITY_ID, HOTJAR_ID } from '@/lib/env';
import Hotjar from '@hotjar/browser';
import Clarity from '@microsoft/clarity';
import { useEffect } from 'react';
import { useAccount } from 'wagmi';
import * as Sentry from '@sentry/browser';
import useProcessTxQueue from '@/hooks/transaction/useProcessTxQueue';
import useNotifSocketUpdates from '@/hooks/leagues/useNotifSocketUpdates';
export enum EVENTS {
  OPEN_TRADE_CTA = 'open trade clicked',
  OPEN_TRADE_SUCCESS = 'open trade successful',
  OPEN_TRADE_FAIL = 'open trade failed',
  APPROVE_TRADE_CTA = 'approve trade clicked',
  APPROVE_TRADE_SUCCESS = 'open trade, approve usdc successfull',
  APPROVE_TRADE_FAIL = 'approve usdc fail, open trade',
  CLOSE_TRADE_CTA = 'close trade clicked',
  CLOSE_TRADE_SUCCESS = 'close trade successful',
  CLOSE_TRADE_FAIL = 'close trade failed',
  CLOSE_LIMIT_ORDER_CTA = 'close limit order clicked',
  CLOSE_LIMIT_ORDER_SUCCESS = 'cancel limit order successful',
  CLOSE_LIMIT_ORDER_FAIL = 'cancel limit order failed',
  EDIT_TPSL_LIMIT_ORDER_CTA = 'edit tpsl of limit order clicked',
  EDIT_TPSL_LIMIT_ORDER_SUCCESS = 'limit order tpsl edit successful',
  EDIT_TPSL_LIMIT_ORDER_FAIL = 'limit order tpsl edit failed',
  EDIT_TPSL_POSITION_CTA = 'edit tpsl of position clicked',
  EDIT_TPSL_POSITION_SUCCESS = 'position tpsl edit successful',
  EDIT_TPSL_POSITION_FAIL = 'position tpsl edit failed',
  MARGIN_UPDATE_APPROVE_CTA = 'USDC approval for margin update clicked',
  MARGIN_UPDATE_APPROVE_SUCCESS = 'approve margin update successful',
  MARGIN_UPDATE_APPROVE_FAIL = 'approve margin update failed',
  MARGIN_UPDATE_CTA = 'margin update clicked',
  MARGIN_UPDATE_SUCCESS = 'margin update successful',
  MARGIN_UPDATE_FAIL = 'margin update failed',
  USDC_DEPOSITED_CTA = 'usdc deposit clicked',
  USDC_DEPOSITED_SUCCESS = 'usdc deposited to 1ct successful',
  USDC_DEPOSITED_FAIL = 'usdc deposited to 1ct failed',
  USDC_WITHDRAWN_CTA = 'usdc withdraw clicked',
  USDC_WITHDRAWN_SUCCESS = 'usdc withdrawn from 1ct successful',
  USDC_WITHDRAWN_FAIL = 'usdc withdrawn from 1ct failed',
  ETH_DEPOSITED_CTA = 'eth deposit clicked',
  ETH_DEPOSITED_SUCCESS = 'eth deposited to 1ct successful',
  ETH_DEPOSITED_FAIL = 'eth deposited to 1ct failed',
  ETH_WITHDRAWN_CTA = 'eth withdraw clicked',
  ETH_WITHDRAWN_SUCCESS = 'eth withdrawn from 1ct successful',
  ETH_WITHDRAWN_FAIL = 'eth withdrawn from 1ct failed',
}
export const trackEvent = (event: EVENTS, extraData?: string[]) => {
  Clarity.setTag(event, extraData ? extraData : []);
  Hotjar.event(event);
};
const AnalyticsProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    address
  } = useAccount();
  useProcessTxQueue();
  useNotifSocketUpdates();
  useEffect(() => {
    const hotjarVersion = 6;
    Hotjar.init(HOTJAR_ID, hotjarVersion);
    Clarity.init(CLARITY_ID);

    // Initializing with `debug` option:
    Hotjar.init(HOTJAR_ID, hotjarVersion, {
      debug: true
    });
  }, []);
  useEffect(() => {
    if (address) {
      Clarity.identify(address);
      Hotjar.identify(address, {});
      Sentry.setUser({
        username: address
      });
    }
  }, [address]);
  return children;
};
export default AnalyticsProvider;