import { League } from '@/components/leagues/types';
import { create } from 'zustand';

type State = {
  selectedWeek: number | null;
  selectedTier: League;
};

type Action = {
  updateLeaguesStore: (key: keyof State, value: any) => void;
  resetStore: () => void;
};

const initialState: State = {
  selectedWeek: null,
  selectedTier: League.Bronze,
};

export const useLeagueStore = create<State & Action>((set) => ({
  ...initialState,
  updateLeaguesStore: (key, value) => set(() => ({ [key]: value })),
  resetStore: () => set(() => initialState),
}));
